.container {
  min-height: calc(100vh - 120px);

  & * {
    font-family: "Cairo", sans-serif !important;
  }
}

.progressContainer {
  position: absolute;
  top: 64px;
  left: 0;
  width: 100%;

  span {
    height: 2px !important;
  }
}

@media (max-width: 600px) {
  .container {
    min-height: calc(100vh - 104px);
  }

  .progressContainer {
    top: 56px;
  }
}

.image_box {
  img {
    @media (max-width: 600px) {
      width: 100%;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }
}
