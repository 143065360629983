.container {
  min-height: calc(100vh - 111px);


  input {
    border: none;
    background-color: transparent !important;
    width: 100%;
    padding: 8px;
  }

  td,
  th {
    padding: 0;
    min-width: fit-content;
  }
}

.progressContainer {
  position: absolute;
  top: 64px;
  left: 0;
  width: 100%;

  span {
    height: 2px !important;
  }
}

@media (max-width: 600px) {
  .container {
    min-height: calc(100vh - 103px);
  }

  .progressContainer {
    top: 56px;
  }
}
