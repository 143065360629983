.progressContainer {
  position: absolute;
  top: 64px;
  left: 0;
  width: 100%;

  span {
    height: 2px !important;
  }
}

@media (max-width: 600px) {
  .container {
    min-height: calc(100vh - 103px);
  }

  .progressContainer {
    top: 56px;
  }
}

.container {
  font-size: 19px;
  margin: 0;
  min-height: calc(100vh - 111px);

  & * {
    font-family: "Cairo", sans-serif !important;
  }

  input {
    background-color: #fff;
    font-size: 18px;
  }

  h1 {
    font-size: 24px;
    color: #20bcd5;
    text-align: center;
    padding: 18px 0 18px 0;
    margin: 32px 0;
    margin-top: 8px;
  }
  h1 span {
    border: 4px dashed #20bcd5;
    padding: 10px;
  }
  p {
    padding: 0;
    margin: 0;
  }
}

.img_circle {
  border-radius: 8px;
  aspect-ratio: 1 / 1;
  object-fit: cover;
}

.section {
  background-color: #fff;
  padding: 16px;
  margin-bottom: 32px;
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
}

.header {
  background-size: cover;
  background-color: transparent;
  border: none;
  box-shadow: none;

  div {
    width: 350px;
    height: 350px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: auto;

    @media (max-width: 575px) {
      width: 280px;
      height: 280px;
    }
  }

  img {
    display: block;
    width: 100%;
    margin: auto;
  }
  p {
    font-size: 48px;
    color: #353538;
    padding-top: 32px;
    margin: 0;
    font-weight: bold;
    text-align: center;
  }
}

.stack span {
  border: none !important;
}

.contact {
  margin-top: 40px;
  display: block;
  border: none !important;
  text-align: center;
  margin-bottom: 16px;

  a {
    color: #000000de;
    font-size: 24px;

    &:hover {
      color: blue;
    }
  }
}

.img_container {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 16px;
  flex-wrap: wrap;
}

.img_box {
  width: 275px;
  aspect-ratio: 1 / 1;
  overflow: hidden;
  border-radius: 6px;
  position: relative;

  img {
    width: 275px;
    aspect-ratio: 1 / 1;
    object-fit: cover;
    object-position: center;
    transition: 0.3s;

    &:hover {
      scale: 1.1;
    }
  }
}
