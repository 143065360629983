.container {
  min-height: calc(100vh - 112px);

  & * {
    font-family: "Cairo", sans-serif !important;
  }

  input {
    background-color: #fff;
  }
}

.progressContainer {
  position: absolute;
  top: 64px;
  left: 0;
  width: 100%;

  span {
    height: 2px !important;
  }
}

@media (max-width: 600px) {
  .container {
    min-height: calc(100vh - 104px);
  }

  .progressContainer {
    top: 56px;
  }
}

.overlay {
  background-color: #fff;
  width: 100%;
  height: 100%;
  top: -100vh;
  left: 0;
  z-index: 999;
  position: fixed;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;

  background-color: #fff;
  // background: rgba(255, 255, 255, 0.13);
  // box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  // backdrop-filter: blur(4px);
  // -webkit-backdrop-filter: blur(4px);

  button {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 16px;
    padding: 8px 16px;
    border-radius: 55px;
    border: 1px solid #999;
    background-color: #fff;
    width: 330px;
    height: 66px;
    font-size: 28px;
    transition: 0.2;
    margin-left: -239px;
    // margin-top: 37px;

    @media (max-width: 600px) {
      margin-left: 0;
      // margin-top: 22px;
    }

    &:hover {
      background-color: #f7faff;
    }

    div {
      display: flex;
    }

    svg {
      width: 32px;
      height: 32px;
    }
  }
}


.spiner {
  margin-left: -239px;

  @media (max-width: 600px) {
    margin-left: 0;
  }
}