* {
  font-family: "Cairo", sans-serif !important;
}

.customHeader {
  /* background-color: #f0f0f0; */
  color: #333; /* Example text color */
  font-weight: 900; /* Example font weight */
}

iframr {
  width: 100% !important;
  height: 100%;
}

/* Carousel */
.carousel {
  height: calc(100vh - 112px);
  overflow: hidden;
  background-color: #000;
}

.carousel-inner {
  height: 100%;
}

.carousel-item {
  height: 100%;
}

.carousel-item img {
  height: 100% !important;
  margin: auto;
}

@media (max-width: 600px) {

  .carousel {
    height: calc(100vh - 104px);
  }

  .carousel-item img {
    height: fit-content !important;
  }
}